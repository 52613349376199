import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../login/login.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticoService {

  private currentIndex : BehaviorSubject<number> = new BehaviorSubject(0);
  private currentTest : BehaviorSubject<any> = new BehaviorSubject(undefined);
  private tests : BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private sanitizer: DomSanitizer
    ) {

  }

  setCurrentTest(test : any, index: number = 0) : any {
    this.currentTest.next(test);
    //this.currentIndex.next(index);
  }

  getCurrentIndex() : any {
    return this.currentIndex;
  }

  getCurrentTest() : any {
    return this.currentTest;
  }

  getTests() : any {
    return this.tests;
  }

  loadTests() : Promise<any> {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

      this.http.post(`${environment.URL_API}/users/tests/getTests`, null, {headers : headers})
      .subscribe((response : any) => {
        this.tests.next(response.data.data);
        this.currentIndex.next(response.data.index);
        resolve(response.data.data);
      }, (error: any) => {
        resolve(false);
      });
    });
  }

  doTest(index: any, opc: string) : Promise<any> {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

      this.http.post(`${environment.URL_API}/users/tests/start?id=${index}&opc=${opc}&next=1`, null, {headers : headers})
      .subscribe((response : any) => {
        resolve(response.data);
      }, (error: any) => {
        resolve(false);
      });
    });
  }

  getImage() : Promise<any> {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

      this.http.post(`${environment.URL_API}/users/tests/image`, null, {headers : headers})
      .subscribe((response : any) => {
        resolve(response.data.image);
      }, (error: any) => {
        resolve(false);
      });
    });
  }

  startTest(index: any) : Promise<any> {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

      this.http.post(`${environment.URL_API}/users/tests/start?id=${index}`, null, {headers : headers})
      .subscribe((response : any) => {
        resolve(response.data);
      }, (error: any) => {
        resolve(false);
      });
    });
  }

  endTest(index: any) : Promise<any> {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

      this.http.post(`${environment.URL_API}/users/tests/end?id=${index}`, null, {headers : headers})
      .subscribe((response : any) => {
        resolve(response.data);
      }, (error: any) => {
        resolve(false);
      });
    });
  }

}

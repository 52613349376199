import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VocacionalService } from '../tests/vocacional/vocacional.service';
import { FasesConfig } from '../../app.fases.config';
import { FasesService } from '../fases/fases.service';
import { IFase } from 'src/app/models/fase.model';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {

  private fase: IFase;
  private areas: BehaviorSubject<any> = new BehaviorSubject([]);
  private facultades: BehaviorSubject<any> = new BehaviorSubject([]);
  private carreras: BehaviorSubject<any> = new BehaviorSubject([]);
  private bancos: BehaviorSubject<any> = new BehaviorSubject([]);
  private estados: BehaviorSubject<any> = new BehaviorSubject([]);
  private municipios: BehaviorSubject<any> = new BehaviorSubject([]);
  private parroquias: BehaviorSubject<any> = new BehaviorSubject([]);
  private years: BehaviorSubject<any> = new BehaviorSubject([]);
  private menciones: BehaviorSubject<any> = new BehaviorSubject([]);
  private materias: BehaviorSubject<any> = new BehaviorSubject([]);
  /*
    Descargar los Json correspondientes a cada periodo,
    Si no cambia nada de la configuración inicial se pueden dejar los archivos .json actuales
  */

  constructor(
    private localStorageService: LocalStorageService,
    public http: HttpClient,
    private vocacionalService: VocacionalService,
    private faseService: FasesService
    ) {
      this.faseService.getCurrentFase()
      .subscribe((fase : IFase) => {
        this.fase = fase;
      });
  }

  initProfile() {
    let years: string[] = [];

    for(let i = 2024; i >= 1950; --i) {
      years.push(String(i));
    }

    this.years.next(years);

    this.initAddress();
  }

  initAddress() {
    this.http.get('assets/data/estados.json').subscribe((estados) =>{
      this.estados.next(estados);
    });

    this.http.get('assets/data/municipios.json').subscribe((municipios) =>{
      this.municipios.next(municipios);
    });

    this.http.get('assets/data/parroquias.json').subscribe((parroquias) =>{
      this.parroquias.next(parroquias);
    });
  }

  initSeleccion() {
    this.http.get('assets/data/areas.json').subscribe((areas) =>{
      this.areas.next(areas);
    });

    this.http.get('assets/data/facultades.json').subscribe((facultades) =>{
      this.facultades.next(facultades);
    });

  }

  initMenciones() {
    this.http.post(`${environment.URL_API}/getForms`, null)
    .subscribe((response : any) =>{
      this.carreras.next(response.data.carreras);
      this.menciones.next(response.data.menciones);
      this.materias.next(response.data.materias);
      this.bancos.next(response.data.bancos);
      this.localStorageService.setItem('carrerasPeriodos', JSON.stringify(response.data.carrerasPeriodos));
    });
  }

  init() : void {
    switch(this.fase.id) {
      case FasesConfig.INSCRIPCIONES:
          this.initSeleccion();
          this.initProfile();
          this.initAddress();
          this.initMenciones();
      break;

      case FasesConfig.TEST_VOCACIONAL:
          this.http.get('assets/data/preguntas_vocacional.json').subscribe((preguntas) =>{
            this.localStorageService.setItem('preguntas_vocacional', JSON.stringify(preguntas));
          });

          this.vocacionalService.getVocacional();
      break;

      case FasesConfig.PUBLICACIONES_PRESELECCION:

      break;

      case FasesConfig.VALIDACION:

      break;

      case FasesConfig.PUBLICACIONES_PRESELECCION_II:

      break;

      case FasesConfig.TEST_DIAGNOSTICO:

      break;

      case FasesConfig.PUBLICACIONES_SELECCIONADOS:

      break;

      default:
          this.initSeleccion();
          this.initProfile();
          this.initAddress();
          this.initMenciones();
      break;
    }

  }

  initAdmin() : void {
    this.http.post(`${environment.URL_API}/getCarreras`, null).subscribe((carreras: any) =>{
      this.localStorageService.setItem('carreras', JSON.stringify(carreras.data));
      this.carreras.next(carreras.data);
    });
  }

  getAreas() : any {
    return this.areas;
  }

  getBancos() : any {
    return this.bancos;
  }

  getFacultades() : any {
    return this.facultades
  }

  getCarreras() : any {
    return this.carreras;
  }

  getMenciones() : any {
    return this.menciones;
  }

  getMaterias() : any {
    return this.materias;
  }

  getEstados() : any {
    return this.estados;
  }

  getMunicipios() : any {
    return this.municipios;
  }

  getParroquias() : any {
    return this.parroquias;
  }

  getYears() : any {
    return this.years;
  }

}

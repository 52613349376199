import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/models/user.model';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private user: IUser;
  private headers : HttpHeaders = null;
  private token : string = "";
  private role : string = "";

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  doLogin(form: any) : any {
    const params = new HttpParams()
    .append('username', form.username)
    .append('password', form.password);

    return this.http.post(`${environment.URL_API}/user/login`, null, {headers : this.headers, params : params});
  }

  doLoginAdmin(form: any) : any {
    const params = new HttpParams()
    .append('username', form.username)
    .append('password', form.password);

    return this.http.post(`${environment.URL_API}/admin/login`, null, {headers : this.headers, params : params});
  }

  logout() : Promise<any>  {
    return new Promise((resolve) => {
      const confirmResult = confirm("Si se desconecta ahora no podra volver a iniciar sesion hasta pasadas 4 horas. ¿Está seguro?");
      resolve(confirmResult);
    });
  }
  /*
  logout() : Promise<any>  {
    return new Promise((resolve, reject) =>{
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.getToken());

      this.http.post(`${environment.URL_API}/logout`, null, {headers : headers})
      .subscribe((response : any) => {
        this.localStorageService.clear();
        location.reload();
        resolve(true);
      }, (error: any) => {
        resolve(false);
      });
    });
  }*/

  isAuthenticated() : boolean {
    if(this.localStorageService.getItem('isLoggedIn') == undefined ) {
      this.localStorageService.setItem('isLoggedIn', 'false');
    }
    return Boolean(JSON.parse(this.localStorageService.getItem('isLoggedIn')));
  }

  setData(data : any) {
    this.token = data.token;
    this.user = { email: data.email, profile: data.profile, username: data.username };
    this.role = data.role;
    this.localStorageService.setItem('token', this.token);
    this.localStorageService.setItem('role', this.role);
    this.localStorageService.setItem('isLoggedIn', 'true');
    this.localStorageService.setItem('user', JSON.stringify(this.user));
  }

  clear() {
    this.localStorageService.setItem('token', "");
    this.localStorageService.setItem('user', null);
    this.localStorageService.setItem('isLoggedIn', 'false');
    this.localStorageService.setItem('role', null);
  }

  getToken() : string {
    if(this.token != "") {
      return this.token;
    }

    this.token = this.localStorageService.getItem('token');
    return this.token;
  }

  getUser() : IUser {
    if(this.user != null) {
      return this.user;
    }

    this.user = JSON.parse(this.localStorageService.getItem('user'));
    return this.user;
  }

  getRole() : string {
    if(this.role != "") {
      return this.role;
    }

    this.role = this.localStorageService.getItem('role');
    return this.role;
  }

}
